export default [
  {
    title: 'Welcome',
    icon: 'HomeIcon',
    tagVariant: 'light-warning',
    route: "admin-home",
    action: "read",
  },
  {
    header: 'Admins & Users',
    permissions: [
      "admin:CanList",
      "users:CanList"
    ]
  },
  {
    title: 'Administrators',
    icon: 'UserPlusIcon',
    tagVariant: 'light-warning',
    route: "admin",
    action: "read",
    permissions: ["admin:CanList"],
    childrenNestedRoutes: [
      'admin-new-user'
    ]
  },
  {
    title: 'Users',
    icon: 'UsersIcon',
    tagVariant: 'light-warning',
    route: "admin-users",
    action: "read",
    permissions: ["users:CanList"],
  },
  {
    title: 'Job Providers',
    icon: 'BriefcaseIcon',
    tagVariant: 'light-warning',
    route: "admin-providers",
    action: "read",
    permissions: ["users:CanList"],
  },
  // {
  //   header: 'Events/Activities',
  //   permissions: [
  //     "events:CanList",
  //     "event_block:CanList",
  //     "event_center:CanList",
  //     "event_accommodation:CanList"
  //   ]
  // },
  // {
  //   title: 'Centers',
  //   icon: 'CastIcon',
  //   tagVariant: 'light-warning',
  //   route: "admin-event-centers",
  //   action: "read",
  //   permissions: ["event_block:CanList"],
  //   childrenNestedRoutes: []
  // },
  // {
  //   title: 'Blocks',
  //   icon: 'GridIcon',
  //   tagVariant: 'light-warning',
  //   route: "admin-event-center-blocks",
  //   action: "read",
  //   permissions: ["event_block:CanList"],
  //   childrenNestedRoutes: []
  // },
  // {
  //   title: 'Accommodation',
  //   icon: 'HomeIcon',
  //   tagVariant: 'light-warning',
  //   route: "admin-event-center-accommodation",
  //   action: "read",
  //   permissions: ["event_accommodation:CanList"],
  //   childrenNestedRoutes: []
  // },
  // {
  //   title: 'Events',
  //   icon: 'SunriseIcon',
  //   tagVariant: 'light-warning',
  //   route: "admin-events",
  //   action: "read",
  //   permissions: ["events:CanList"],
  //   childrenNestedRoutes: [
  //     // 'admin-new-user'
  //   ]
  // },
  {
    header: 'App Settings',
    permissions: [
      "settings:CanList",
      "settings:CanUpdate",
      "policy:CanList",
      "policy:CanView",
      "user_role:CanList",
      "resource:CanList"
    ]
  },
  {
    title: 'Settings',
    icon: 'SettingsIcon',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'General',
        icon: 'SettingsIcon',
        tagVariant: 'light-warning',
        route: "admin-settings",
        action: "read",
        permissions: ["settings:CanList"],
      },
    ],
    childrenNestedRoutes: [
      'admin-new-permission',
      'admin-update-permission'
    ]
  },
  {
    title: 'Administration',
    icon: 'MinusCircleIcon',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Resources',
        icon: 'BoxIcon',
        tagVariant: 'light-warning',
        route: "admin-resources",
        action: "read",
        permissions: ["resource:CanList"],
      },
      {
        title: 'Policies',
        icon: 'LockIcon',
        tagVariant: 'light-warning',
        route: "admin-policies",
        action: "read",
        permissions: ["policy:CanList"],
      },
      {
        title: 'Roles',
        icon: 'LockIcon',
        tagVariant: 'light-warning',
        route: "admin-user-roles",
        action: "read",
        permissions: ["user_role:CanList"],
      },
    ],
    childrenNestedRoutes: [
      'admin-resources',
      'admin-new-resources',
      'admin-update-resource',
      'admin-resource-actions',
      'admin-new-resource-action',
      'admin-update-resource-action',
      'admin-policies',
      'admin-new-policy',
      'admin-user-roles',
      'admin-view-policies',
      'admin-new-user-role',
      'admin-update-user-role',
    ]
  },
  {
    header: 'Others',
    permissions: [
      "faqs:CanList",
      "blogs:CanList",
      "admin_logs:CanList"
    ]
  },
  {
    title: 'Resources',
    action: "read",
    route: "admin-blogs",
    icon: 'ClipboardIcon',
    tagVariant: 'light-warning',
    permissions: ["blogs:CanList"],
    childrenNestedRoutes: [
      'admin-new-blog',
      'admin-single-blog',
      'admin-update-blog',
    ]
  },
  {
    title: 'Church Map',
    action: "read",
    route: "admin-church-map",
    icon: 'CastIcon',
    tagVariant: 'light-warning',
    permissions: ["church_map:CanList"],
    childrenNestedRoutes: []
  },
  {
    title: 'Logs',
    icon: 'ListIcon',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Admin Logs',
        action: "read",
        icon: 'ListIcon',
        route: "admin-logs",
        tagVariant: 'light-warning',
        permissions: ["admin_logs:CanList"]
      },
      {
        title: 'User Logs',
        action: "read",
        icon: 'ListIcon',
        route: "user-logs",
        tagVariant: 'light-warning',
        permissions: ["logs:CanList"]
      },
      {
        title: 'Audit Trail',
        action: "read",
        route: "audit-trail",
        icon: 'GitBranchIcon',
        tagVariant: 'light-warning',
        permissions: ["audit_trail:CanList"]
      },
    ],
    childrenNestedRoutes: [
      'admin-logs',
      'user-logs',
      'audit-trail'
    ]
  },
]
